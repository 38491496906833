<div class="application-view-main-container">
    <div class="application-view-title">Konto użytkownika</div>
    <div *ngIf="userData" class="main-container">
        <!-- <div class="info-bar">
            <div class="section-one">
                <span>CZY ZAKWALIFIKOWANO DO AKCJI PROMOCYJNEJ?</span>
                &nbsp;
                <span *ngIf="userData.qualified" style="color: green;">TAK</span>
                <span *ngIf="!userData.qualified" style="color: var(--error-color);">NIE</span>
            </div>
            <div class="section-two">
                <span>Liczba zdobytych punktów:</span>
                &nbsp;
                <span style="color: var(--error-color);">{{userData.points}}</span>
            </div>
        </div> -->
        <form #myForm="ngForm" class="form-container">
            <div class="email-container">
                <span>Email:</span>
                &nbsp;
                <span>{{userData.userEmail}}</span>
                <!-- <input type="text" class="form-control" name="userEmail" [(ngModel)]="userData.userEmail"/> -->
            </div>
            <p style="padding: 0; margin: 0; margin-top: 1rem; font-weight: 500;">Promocja:</p>
            <p style="padding: 0; margin: 0;">(Miejsce w rankingu i wartość nagrody nie są stałe, mogą się zmieniać podczas trwania promocji.)</p>
            <div class="promo-container">
                <span>Liczba zdobytych punktów:</span>
                &nbsp;
                <span style="color: var(--error-color);">{{pointsTotal}}</span>
            </div>
            <!-- <div class="promo-container">
                <span>Czy zakwalifikowano do akcji promocyjnej?:</span>
                &nbsp;
                <span *ngIf="userData.qualified" style="color: green;">TAK</span>
                <span *ngIf="!userData.qualified" style="color: var(--error-color);">NIE</span>
            </div> -->
            <div class="promo-container">
                <span>Wartość przypisanej nagrody:</span>
                &nbsp;
                <span>{{userData.prizeAmount}}</span>
                &nbsp;
                <span>zł</span>
            </div>
            <div *ngIf="rankingData?.ranking" class="promo-container">
                <span>Miejsce w rankingu:</span>
                &nbsp;
                <span style="color: var(--error-color);">{{userData.registered ? userData.position : '-'}}</span>
            </div>
            <div *ngIf="rankingData?.samePointsCount" class="promo-container">
                <span>Liczba użytkowników z taką samą liczbą punktów:</span>
                &nbsp;
                <span style="color: var(--error-color);">{{userData.registered ? rankingData.samePointsCount : '-'}}</span>
            </div>
            <p style="padding: 0; margin: 0; margin-top: 1rem; font-weight: 500;">Firma:</p>
            <div class="login-form-input-description-container">
                <span>Nazwa firmy</span>
                <input type="text" class="form-control" name="companyName" [(ngModel)]="userData.companyName"/>
            </div>
            <div class="login-form-input-description-container">
                <span>NIP</span>
                <input type="text" class="form-control" name="nipCode" [(ngModel)]="userData.nipCode"/>
            </div>
            <div class="login-form-input-description-container">
                <span>Imię</span>
                <input type="text" class="form-control" name="firstName" [(ngModel)]="userData.firstName"/>
            </div>
            <div class="login-form-input-description-container">
                <span>Nazwisko</span>
                <input type="text" class="form-control" name="lastName" [(ngModel)]="userData.lastName"/>
            </div>
            <div class="login-form-input-description-container">
                <span>Numer telefonu</span>
                <input type="tel" class="form-control" name="phoneNumber" [(ngModel)]="userData.phoneNumber"/>
            </div>
            <p style="padding: 0; margin: 0; margin-top: 1rem; font-weight: 500;">Adres wysyłki nagród:</p>
            <div class="login-form-input-description-container">
                <span>Ulica</span>
                <input type="text" class="form-control" name="streetName" [(ngModel)]="userData.streetName"/>
            </div>
            <div class="login-form-input-description-container">
                <span>Numer budynku/mieszkania</span>
                <input type="text" class="form-control" name="buildingNumber" [(ngModel)]="userData.buildingNumber"/>
            </div>
            <div class="login-form-input-description-container">
                <span>Kod pocztowy</span>
                <input type="text" class="form-control" name="postalCode" [(ngModel)]="userData.postalCode"/>
            </div>
            <div class="login-form-input-description-container">
                <span>Miasto</span>
                <input type="text" class="form-control" name="city" [(ngModel)]="userData.city"/>
            </div>
            <div class="login-form-input-description-container">
                <span>Województwo</span>
                <input type="text" class="form-control" name="voivodeship" [(ngModel)]="userData.voivodeship"/>
            </div>
            <p style="padding: 0; margin: 0; margin-top: 1rem; font-weight: 500;">Zarejestrowane produkty:</p>
            <!-- <div *ngFor="let product of userData.registeredProducts; let i=index" class="item-row">
                <span>{{i+1}}.</span>
                &nbsp;
                <div class="product-presenter">
                    <span>Symbol produktu: {{product.productName}}</span>
                    <span>Nr seryjny: {{product.productSerial}}</span>
                    <div>
                        <span>Punkty: </span>
                        &nbsp;
                        <span style="color: var(--error-color);">{{product.score}}</span>
                    </div>
                </div>
            </div> -->
            <div *ngFor="let product of userData.registeredProducts; let i=index" class="item-row">
                <span class="index">{{i+1}}.</span>
                <div class="product-presenter">
                    <span class="product-symbol">Symbol produktu: {{product.productName}}</span>
                    <span class="product-serial">Nr seryjny: {{product.productSerial}}</span>
                    <span class="product-date">Data rejestracji: {{product.date}}</span>
                    <div class="product-points">
                        <span>Punkty: </span>
                        <span class="score" style="color: var(--error-color);">{{product.score}}</span>
                    </div>
                </div>
            </div>
        </form>
        <div class="submit-button-container">
            <button type="button" class="btn btn-sm btn-secondary btn-secondary-custom"
            (click)="redirectToAdministrationScreen()">Wróć</button>
            <button class="btn btn-primary btn-primary-custom" type="submit" (click)="executeSaveChanges()">Zapisz zmiany</button>
        </div>
    </div>
    <!-- Add other form controls here -->
</div>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Przetwarzanie...'"></waiting-spinner>
<alert></alert>

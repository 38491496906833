<div class="application-view-main-container">
  <div class="application-view-title">Zarządzanie danymi</div>
  <div class="available-data-actions-list-container">
    <database-management-presenter [actionTitle]="loadUsersTitle"
      [actionDescription]="loadUsersText"
      [useOpenExcel]="true"
      (executeAction)="loadNewUsers($event)"></database-management-presenter>
    <database-management-presenter [actionTitle]="updateRegisteredProductsListTitle"
      [actionDescription]="updateRegisteredProductsListText"
      [useOpenExcel]="true"
      (executeAction)="updateRegisteredProductsList($event)"></database-management-presenter>
      <database-management-presenter 
      [actionTitle]="loadScoringTitle" 
      [actionDescription]="loadScoringText"
      [useOpenExcel]="true" 
      (executeAction)="loadNewScoring($event)"></database-management-presenter>
      <database-management-presenter [actionTitle]="loadPrizesTitle"
      [actionDescription]="loadPrizesText"
      [useOpenExcel]="true"
      (executeAction)="loadNewPrizes($event)"></database-management-presenter>
      <database-management-presenter [actionTitle]="loadBenefitsTitle"
        [actionDescription]="loadBenefitsText"
        [useOpenExcel]="true"
        (executeAction)="loadNewBenefits($event)"></database-management-presenter>
      <database-management-presenter [actionTitle]="deleteUsersTitle"
        [actionDescription]="deleteUsersText"
        [useOpenExcel]="true"
        (executeAction)="deleteUsers($event)"></database-management-presenter>
  </div>
  <div class="return-button-container">
    <button type="button" class="btn btn-sm btn-secondary btn-secondary-custom"
      (click)="redirectToAdministrationScreen()">Wróć</button>
  </div>
</div>
<waiting-spinner *ngIf="isSpinnerVisible" [text]="'Modyfikowanie danych...'"></waiting-spinner>
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BenefitsLoaderService } from 'src/app/dataExchange/benefits-loader.service';
import { UsersLoaderService } from 'src/app/dataExchange/users-loader.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { InfoDialogComponent } from '../../common/info-dialog/info-dialog.component';
import { ScoringLoaderService } from 'src/app/dataExchange/scoring-loader.service';
import { PrizesLoaderService } from 'src/app/dataExchange/prizes-loader.service';

@Component({
  selector: 'database-management-view',
  templateUrl: './database-management-view.component.html',
  styleUrls: ['./database-management-view.component.css']
})
export class DatabaseManagementComponent {

  private _isSpinnerVisible: boolean = false;

  public loadUsersTitle = "Ładowanie listy użytkowników"
  public loadUsersText = 'Wczytanie listy użytkowników wraz z haslami pierwszego logowania spowoduje ' + 
  'sprawdzenie czy dany użytkownik znajduje się w bazie danych. Jeżeli użytkownik nie zostanie znaleziony w bazie danych zostanie ' +
  'utworzone dla niego nowe konto.'
  private loadUsersSuccessMessage = 'Konta użytkowników zostały utworzone.';
  private loadUsersErrorMessage = 'Nie udało się poprawnie utworzyć kont użytkowników. ' + 
  'Zweryfikuj plik z danymi. Możliwe, że ' +
  'są one niekompletne lub niepoprawne.';

  public updateRegisteredProductsListTitle: string = 'Aktualizacja listy zarejestrowanych urządzeń';
  public updateRegisteredProductsListText: string = 'Wczytanie listy zarejestrowanych przez użytkowników urządzeń spowoduje ' + 
    'aktualizację kont użytkowników o dane dostarczone na liscie i ponowne przeliczenie punktacji. ' +
    'Lista zarejestrowanych przez użytkowników urządzeń musi być zapewniona w pliku *.xlsx.';
  private updateRegisteredProductsListSuccessMessage = 'Konta użytkowników zostały zaktualizowane na podstawie danych z listy.';
  private updateRegisteredProductsListErrorMessage = 'Nie udało się poprawnie zaktualizować kont użytkowników.' +
    'Zweryfikuj plik z danymi. Możliwe, że ' +
    'są one niekompletne lub niepoprawne.';

  public loadScoringTitle: string = 'Ładowanie danych punktacji';
  public loadScoringText: string = 'Wczytanie nowej listy punktacji spowoduje nadpisanie ' +
  'poprzedniej. Dotychczasowa punktacja poszczególnych użytkowników pozostanie niezmieniona, ' +
  'w celu jej uaktualnienia należy po wykonaniu tej operacji wczytać listę zarejestrowanych urządzeń. Lista nowej ' +
  'punktacji musi być zapewniona w pliku *.xlsx.';
  private loadScoringSuccessMessage: string = 'Punktacja została zaktualizowana.';
  private loadScoringErrorMessage: string = 'Nie udało się zaktualizować punktacji. ' +
  'Zweryfikuj poprawnośc pliku z danymi. Możliwe, że tylko część danych została niewczytana.';

  public loadPrizesTitle: string = 'Ładowanie listy nagród';
  public loadPrizesText: string = 'Wczytanie nowej listy nagród spowoduje nadpisanie poprzedniej. ' +
    'Lista nagród powinna zopstać wczytana raz na początku, przed listą zarejestrowanych urzadzeń. ' +
    'Lista musi być zapewniona w pliku *.xlsx.';
  private loadPrizesSuccessMessage: string = 'Lista nagród została zaktualizowana.';
  private loadPrizesErrorMessage: string = 'Nie udało się zaktualizować listy nagrod. ' +
    'Zweryfikuj poprawnośc pliku z danymi. Możliwe że tylko część danych została niewczytana.';

  public loadBenefitsTitle: string = 'Ładowanie danych benefitów';
  public loadBenefitsText: string = 'Wczytanie nowej listy benefitów spowoduje usunięcie ' +
    'wszystkich danych zamówień benefitów. Lista użytkowników pozostanie niezmieniona. ' +
    'Przeprowadzając tę operację zniszczysz bezpowrotnie dane zamówień. Lista nowych ' +
    'benefitów musi być zapewniona w pliku *.xlsx.';
  private loadBenefitsSuccessMessage: string = 'Dane benefitów zostały zaktualizowane.';
  private loadBenefitsErrorMessage: string = 'Nie udało się zmienić danych wszystkich benefitów. ' +
    'Zweryfikuj poprawnośc pliku z danymi. Możliwe że tylko część danych została niewczytana.';

  public deleteUsersTitle: string = 'Usuwanie użytkowników';
  public deleteUsersText: string = 'Wczytanie listy adresów email spowoduje usunięcie kont ' +
    'użytkowników posiadających adres zawarty na liście. Wraz z kontami zostaną usunięte również dane teleadresowe i zamówienia. ' +
    'Lista adresów email musi być zapewniona w pliku *.xlsx.';
  private deleteUsersSuccessMessage: string = 'Konta użytkowników zostały usunięte.';
  private deleteUsersErrorMessage: string = 'Nie udało się usunąć wszystkich kont użytkowników. ' +
    'Zweryfikuj poprawnośc pliku z danymi. Możliwe że tylko część danych została usunięta.';

  public get isSpinnerVisible() {
    return this._isSpinnerVisible;
  }

  constructor(private navigator: NavigatorService,
    private usersLoader: UsersLoaderService,
    private prizesLoader: PrizesLoaderService,
    private scoringLoader: ScoringLoaderService,
    private benefitsLoader: BenefitsLoaderService,
    private dialog: MatDialog) {
  }

  public redirectToAdministrationScreen() : void {
    this.navigator.redirectToAdministrationScreen();
  }

  public async updateRegisteredProductsList(excelFile: any) : Promise<void> {
    this._isSpinnerVisible = true;
    const updateResult = await this.usersLoader.executeUsersRegisteredProductsListUpdate(excelFile);
    this._isSpinnerVisible = false;
    if (updateResult) {
      this.showSuccessDialog(this.updateRegisteredProductsListSuccessMessage);
    } else {
      this.showErrorDialog(this.updateRegisteredProductsListErrorMessage);
    }
  }
  
  public async loadNewPrizes(excelFile: any) : Promise<void> {
    this._isSpinnerVisible = true;
    const prizesLoadResult = await this.prizesLoader.executePrizesReplacement(excelFile);
    this._isSpinnerVisible = false;
    if (prizesLoadResult) {
      this.showSuccessDialog(this.loadPrizesSuccessMessage);
    } else {
      this.showErrorDialog(this.loadPrizesErrorMessage);
    }
  }
  
  public async loadNewScoring(excelFile: any) : Promise<void> {
    this._isSpinnerVisible = true;
    const scoringLoadResult = await this.scoringLoader.executeScoringReplacement(excelFile);
    this._isSpinnerVisible = false;
    if (scoringLoadResult) {
      this.showSuccessDialog(this.loadScoringSuccessMessage);
    } else {
      this.showErrorDialog(this.loadScoringErrorMessage);
    }
  }

  public async loadNewBenefits(excelFile: any) : Promise<void> {
    this._isSpinnerVisible = true;
    const benefitsLoadResult = await this.benefitsLoader.executeBenefitsReplacement(excelFile);
    this._isSpinnerVisible = false;
    if (benefitsLoadResult) {
      this.showSuccessDialog(this.loadBenefitsSuccessMessage);
    } else {
      this.showErrorDialog(this.loadBenefitsErrorMessage);
    }
  }

  public async deleteUsers(excelFile: any) : Promise<void> {
    this._isSpinnerVisible = true;
    const deleteUsersResult = await this.usersLoader.deleteUsers(excelFile);
    this._isSpinnerVisible = false;
    if (deleteUsersResult) {
      this.showSuccessDialog(this.deleteUsersSuccessMessage);
    } else {
      this.showErrorDialog(this.deleteUsersErrorMessage);
    }
  }

  private showSuccessDialog(message: string) : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Sukces',
      message: message,
      isErrorInfo: false,
      okFunc: null
    } });
  }

  private showErrorDialog(message: string) : void {
    this.dialog.open(InfoDialogComponent, { disableClose: true, data: {
      title: 'Błąd',
      message: message,
      isErrorInfo: true,
      okFunc: null
    } });
  }

  //zostało bo łatwo można usunąć listę użytkowników nie będących admininistratorami z firebase wczytujac pustą listę 
  public async loadNewUsers(excelFile: any) : Promise<void> {
    this._isSpinnerVisible = true;
    const usersLoadResult = await this.usersLoader.executeAddNewUsers(excelFile);
    this._isSpinnerVisible = false;
    if (usersLoadResult) {
      this.showSuccessDialog(this.loadUsersSuccessMessage);
    } else {
      this.showErrorDialog(this.loadUsersErrorMessage);
    }
  }
}

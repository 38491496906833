<dialog-base [width]="25" [height]="20" [isError]="isErrorInfo">
    <div header-slot>Jak uzyskać hasło?</div>
    <div content-slot>
      <div>
        <span class="text-black">Po zarejestrowaniu gwarancji na pompę objętą kampanią, </span>
        <span class="text-orange">na e-mail zapisany w ProClub wysłane jest jednorazowe hasło </span>
        <span class="text-black">do aktywacji konta pana-store.</span>
    </div>
    </div>
    <div footer-slot class="dialog-buttons-container">
      <button type="button" class="btn btn-primary btn-primary-custom" (click)="confirmMessage()">
        OK
      </button>
    </div>
  </dialog-base>
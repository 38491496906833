import { Injectable } from '@angular/core';
import axios from 'axios';
import { FirebaseOptions } from 'firebase/app';
import { collection } from 'firebase/firestore';

const headers = {
  'Content-Type': 'application/json'
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // private _apiBaseEndpoint: string = "http://localhost:3000/api"; //dev
  private _apiBaseEndpoint: string = "https://www.pana-store.pl/api"; //prod

  public get apiBaseEndpoint() {
    return this._apiBaseEndpoint;
  }

  public async getFirestoreConfig() : Promise<FirebaseOptions> {
    return await this.sendGetRequest('getfireconfig');
  }

  public async getAllExistingUsersEmailAddresses() : Promise<Array<string>> {
    return await this.sendGetRequest('alluseremails');
  }

  public async removeNonAdminUsers(adminEmailAddresses: Array<string>) : Promise<any> {
    return await this.sendPostRequest('removenonadmins', { adminsEmails: adminEmailAddresses });
  }

  public async removeUsersByEmails(emailAddresses: Array<string>) : Promise<any> {
    return await this.sendPostRequest('removeusersbyemails', { usersEmails: emailAddresses });
  }

  public async addUsers(usersData: Array<any>) : Promise<Array<string>> {
    return await this.sendFirebasePostRequest('addusers', { usersData: usersData });
  }

  public async sendOrderConfirmationEmail(templateData: any){
    await this.sendPostRequest('sendemail', templateData);
  }

  public async sendPasswordResetEmail(templateData: any){
    await this.sendPostRequest('sendpasswresetemail', templateData);
  }

  public async sendHelloEmails(emailsData: any){
    await this.sendPostRequest('sendhelloemails', emailsData);
  }

  //MongoDB
  public async getCollectionData(collectionName: string){
    return await this.sendGetRequest(`getcollectiondata?collectionname=${collectionName}`);
  }

  public async getDocumentById(collectionName: any, id:any){
    return await this.sendGetRequest(`getdocumentbyid?collectionname=${collectionName}&id=${id}`);
  }

  public async getDocumentsByPropertyValue(collectionName: any, propertyName:any, propertyValue: any){
    return await this.sendGetRequest(`getdocumentsbypropertyvalue/?collectionname=${collectionName}&propertyname=${propertyName}&propertyvalue=${propertyValue}`);
  }

  public async addObjectToCollection(collectionName: any, object: any){
    await this.sendPostRequest(`addobjecttocollection?collectionname=${collectionName}`, object);
  }

  public async addObjectsToCollection(collectionName: any, objects: Array<any>){
    await this.sendPostRequest(`addobjectstocollection?collectionname=${collectionName}`, objects);
  }

  public async deleteCollectionData(collectionName: any){
    await this.sendDeleteRequest(`clearcollection?collectionname=${collectionName}`);
  }

  public async replaceCollectionData(collectionName: any, object: any){
    return await this.sendPostRequest(`replacecollectiondata?collectionname=${collectionName}`, object);
  }

  public async clearWinnersQualifiedStatus(){
    return await this.sendPostRequest(`clearusersqualifiedstatus/`, {});
  }

  public async updateWinnersQualifiedStatus(objects: Array<any>){
    return await this.sendPostRequest(`updateusersqualifiedstatus/`, objects);
  }

  public async getUserDataByEmail(userEmail: any){
    return await this.sendGetRequest(`getuserdatabyemail?useremail=${userEmail}`);
  }

  public async updateUserData(userData: any){
    return await this.sendPostRequest(`updateuserdata`, userData);
  }

  public async registerUserData(userData: any){
    return await this.sendPostRequest(`registeruserdata`, userData);
  }

  public async getUserOrdersByEmail(userEmail: any){
    return await this.sendGetRequest(`getuserordersbyemail?useremail=${userEmail}`);
  }

  public async deleteUsersDataByEmails(userEmails: any){
    return await this.sendPostRequest(`deleteusersdatabyemails`, userEmails);
  }

  public async deleteUsersOrdersByEmails(userEmails: any){
    return await this.sendPostRequest(`deleteusersordersbyemails`, userEmails);
  }

  //

  private async sendGetRequest(relativeRoute: string) : Promise<any> {
    let response: any = null;
    try {
      response = await axios.get(`${this.apiBaseEndpoint}/${relativeRoute}`, { withCredentials: true });
      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Unexpected Get Response Status Code: ${response.status}.`);
      }
      return response.data;
    } catch(e) {
      console.error(e);
      return null;
    }
  }

  private async sendPostRequest(relativeRoute: string, payload: any): Promise<any> {
    let response: any = null;
    try {
      response = await axios.post(`${this.apiBaseEndpoint}/${relativeRoute}`, payload, { withCredentials: true, headers: headers });
      // console.log(" response ", response);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Unexpected Get Response Status Code: ${response.status}.`);
      }
      if (response.data){
        return response.data;
      }
      else return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  private async sendFirebasePostRequest(relativeRoute: string, payload: any): Promise<any> {
    let response: any = null;
    try {
      response = await axios.post(`${this.apiBaseEndpoint}/${relativeRoute}`, payload, { withCredentials: true, headers: headers });
      // console.log(" response ", response);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Unexpected Get Response Status Code: ${response.status}.`);
      }
      if (response){
        return response;
      }
      else return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  private async sendDeleteRequest(relativeRoute: string): Promise<any> {
    let response: any = null;
    try {
      response = await axios.delete(`${this.apiBaseEndpoint}/${relativeRoute}`, { withCredentials: true });
      if (response.status < 200 || response.status >= 300) {
        throw new Error(`Unexpected Delete Response Status Code: ${response.status}.`);
      }
      return response.data;
    } catch(e) {
      console.error(e);
      return null;
    }
  }
}
